/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*
Writers note. The format is: each month is talking about work done in that month.
e.g. July talks about what shipped in July. Ideally this example content is released at the beginning of August.

The homepage must also be manually updated, found in src/pages/index.jsx
*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Banner, Tag, TagGroup} from '@servicetitan/design-system';
import {Link} from 'components';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    ul: "ul",
    li: "li",
    p: "p",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, "\n", "\n", React.createElement(Banner, {
    className: "m-b-3"
  }, "For a comprehensive list of changes, view ", React.createElement(Link, {
    el: "span",
    primary: true,
    href: "https://github.com/servicetitan/anvil/releases"
  }, "our releases"), " in Github."), "\n", React.createElement(_components.h1, {
    id: "april-2023",
    style: {
      position: "relative"
    }
  }, "April 2023", React.createElement(_components.a, {
    href: "#april-2023",
    "aria-label": "april 2023 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v13.2")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Content guidelines for:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Checkbox"), "\n", React.createElement(_components.li, null, "Color Picker"), "\n", React.createElement(_components.li, null, "File Picker"), "\n", React.createElement(_components.li, null, "Filter Button"), "\n", React.createElement(_components.li, null, "Radio"), "\n", React.createElement(_components.li, null, "Textarea"), "\n", React.createElement(_components.li, null, "Toggle Switch"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Updates to Sidebar collapse logic that collapses the component on devices smaller than desktop"), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "february-2023",
    style: {
      position: "relative"
    }
  }, "February 2023", React.createElement(_components.a, {
    href: "#february-2023",
    "aria-label": "february 2023 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v13.1"), React.createElement(Tag, null, "v13.0")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "DatePicker refactoring with better handling and keyboard behavior"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Updates to OptionList to have more clickable space"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Content guidelines and updated design spec for Action Menu"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Content guidelines for Buttons"), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "january-2023",
    style: {
      position: "relative"
    }
  }, "January 2023", React.createElement(_components.a, {
    href: "#january-2023",
    "aria-label": "january 2023 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v12.11")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "New pattern page on ", React.createElement(_components.a, {
    href: "/patterns/forms/"
  }, "Forms"), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Bugfixes to our ", React.createElement(_components.a, {
    href: "/components/date-range-picker"
  }, "Date Range Picker"), " and ", React.createElement(_components.a, {
    href: "/components/date-picker"
  }, "Date Picker"), "."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "december-2022",
    style: {
      position: "relative"
    }
  }, "December 2022", React.createElement(_components.a, {
    href: "#december-2022",
    "aria-label": "december 2022 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v12.10"), React.createElement(Tag, null, "v12.9")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Updates the ", React.createElement(_components.a, {
    href: "/components/progress-tracker/"
  }, "Progress Tracker"), " to improve usability, accessiblity, and new features."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Updates the ", React.createElement(_components.a, {
    href: "/components/side-nav/"
  }, "Side Nav"), " with new features and updated visuals."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Refactoring of our Focus Trapping."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "november-2022",
    style: {
      position: "relative"
    }
  }, "November 2022", React.createElement(_components.a, {
    href: "#november-2022",
    "aria-label": "november 2022 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v12.8")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Minor fixes to Date Range Picker, portal option."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Updates to Anvil's internal release system."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "october-2022",
    style: {
      position: "relative"
    }
  }, "October 2022", React.createElement(_components.a, {
    href: "#october-2022",
    "aria-label": "october 2022 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v12.7")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Improvements to Time Picker scrolling behavior on open."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Various bug fixes to our Date Picker and Date Range Picker."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "september-2022",
    style: {
      position: "relative"
    }
  }, "September 2022", React.createElement(_components.a, {
    href: "#september-2022",
    "aria-label": "september 2022 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v12.6"), React.createElement(Tag, null, "v12.5")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Added counter support for Input and Textarea"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Improve our Frame's handling, fix issues with disappearing page content on browser resize."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "august-2022",
    style: {
      position: "relative"
    }
  }, "August 2022", React.createElement(_components.a, {
    href: "#august-2022",
    "aria-label": "august 2022 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v12.4"), React.createElement(Tag, null, "v12.3")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Improves the accessibility of the Select (contrast, aria attributes, secondary actions are now always visible)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Improves the Takeover header on small displays"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Timezone Provider is now available for the Date Picker and Date Range Picker"), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "july-2022",
    style: {
      position: "relative"
    }
  }, "July 2022", React.createElement(_components.a, {
    href: "#july-2022",
    "aria-label": "july 2022 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v12.2"), React.createElement(Tag, null, "v12.1")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "For accessibility, text colors were updated in several components for higher contrast."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Moved tokens into its own repository."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "june-2022",
    style: {
      position: "relative"
    }
  }, "June 2022", React.createElement(_components.a, {
    href: "#june-2022",
    "aria-label": "june 2022 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v11.9"), React.createElement(Tag, null, "v11.8")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Added class name properties to Drawer, Modal."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Select now supports the indeterminate visual state for tree views."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "may-2022",
    style: {
      position: "relative"
    }
  }, "May 2022", React.createElement(_components.a, {
    href: "#may-2022",
    "aria-label": "may 2022 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v11.7"), React.createElement(Tag, null, "v11.6")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "The font Sofia Pro went from Medium weight to Bold weight."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Time Picker now supports 24hr format."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Improved customization of Anvil Select footer, File Picker button."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Added more examples of Table usage to our documentation."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "april-2022",
    style: {
      position: "relative"
    }
  }, "April 2022", React.createElement(_components.a, {
    href: "#april-2022",
    "aria-label": "april 2022 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v11.5")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Added the AnvilProvider wrapper component"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Dependency and security updates"), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "march-2022",
    style: {
      position: "relative"
    }
  }, "March 2022", React.createElement(_components.a, {
    href: "#march-2022",
    "aria-label": "march 2022 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Added a new neutral shade: ", React.createElement(_components.a, {
    href: "/foundations/color/#neutral"
  }, "Neutral 500"), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Bugfixes, particularly around icons and tables."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Minor security updates via dependency updates."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "february-2022",
    style: {
      position: "relative"
    }
  }, "February 2022", React.createElement(_components.a, {
    href: "#february-2022",
    "aria-label": "february 2022 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v11.3"), React.createElement(Tag, null, "v11.4")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "New pattern page on ", React.createElement(_components.a, {
    href: "/patterns/sort-and-filter/"
  }, "Sort and Filter"), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Support for Firefox and Microsoft Edge."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "january-2022",
    style: {
      position: "relative"
    }
  }, "January 2022", React.createElement(_components.a, {
    href: "#january-2022",
    "aria-label": "january 2022 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v11.2")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Nunito Sans semibold has been largely phased out for bold."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "New foundation page on ", React.createElement(_components.a, {
    href: "/foundations/international-design/"
  }, "International Design"), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "New foundation page on ", React.createElement(_components.a, {
    href: "/foundations/theming/"
  }, "Theming"), "."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "december-2021",
    style: {
      position: "relative"
    }
  }, "December 2021", React.createElement(_components.a, {
    href: "#december-2021",
    "aria-label": "december 2021 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v11.0"), React.createElement(Tag, null, "v11.1")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "New component: ", React.createElement(_components.a, {
    href: "/components/time-picker/"
  }, "Time Picker"), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "New component: ", React.createElement(_components.a, {
    href: "/components/filter-button/"
  }, "Filter Button"), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "We replaced our internal focus scope with a FocusTrap component."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "november-2021",
    style: {
      position: "relative"
    }
  }, "November 2021", React.createElement(_components.a, {
    href: "#november-2021",
    "aria-label": "november 2021 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v10.6"), React.createElement(Tag, null, "v10.7"), React.createElement(Tag, null, "v10.8"), React.createElement(Tag, null, "v11.0")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "We refactored our Token 80 value from #9e9ea0 to #949596 so that we have a gray value that has a 3:1 contrast with pure white."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Our ", React.createElement(_components.a, {
    href: "/components/table/"
  }, "Table documentation"), " has been updated to feature more possibilities."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "A handful of minor enhancements have been done to the ", React.createElement(_components.a, {
    href: "/components/date-range-picker/"
  }, "Date Range Picker"), "."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "october-2021",
    style: {
      position: "relative"
    }
  }, "October 2021", React.createElement(_components.a, {
    href: "#october-2021",
    "aria-label": "october 2021 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v10.4"), React.createElement(Tag, null, "v10.5")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Our Select now has a ", React.createElement(_components.a, {
    href: "/components/anvil-select/#custom-footer"
  }, "custom footer option"), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Our ", React.createElement(_components.a, {
    href: "/components/input/#addons"
  }, "Input's addon style"), " has removed the gray background and border."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "september-2021",
    style: {
      position: "relative"
    }
  }, "September 2021", React.createElement(_components.a, {
    href: "#september-2021",
    "aria-label": "september 2021 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v10.3")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "We now have a ", React.createElement(_components.a, {
    href: "https://anvil.st.dev/?path=/story/utilities-themeprovider--default"
  }, "Theme Provider")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "/components/date-range-picker"
  }, "Date Range Picker"), " now supports a custom display value."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "A ", React.createElement(_components.a, {
    href: "http://localhost:8000/resources/contributing/"
  }, "contribution page"), " for Anvil now exists."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "saugust-2021",
    style: {
      position: "relative"
    }
  }, "SAugust 2021", React.createElement(_components.a, {
    href: "#saugust-2021",
    "aria-label": "saugust 2021 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Tag, null, "v10.2"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Our Figma plan was upgraded to now support shared fonts, design system analytics, and branching."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "We made minor enhancements to our ", React.createElement(_components.a, {
    href: "/components/inline-edit#placeholder"
  }, "Inline Edit"), ", now supporting placeholder values, and our ", React.createElement(_components.a, {
    href: "/components/option-list/#indeterminate-visual"
  }, "Option List"), " supporting an indeterminate state."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "july-2021",
    style: {
      position: "relative"
    }
  }, "July 2021", React.createElement(_components.a, {
    href: "#july-2021",
    "aria-label": "july 2021 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v9.6"), React.createElement(Tag, null, "v10.0"), React.createElement(Tag, null, "v10.1")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Our ", React.createElement(_components.a, {
    href: "/components/sidebar"
  }, "Sidebar"), " now has built-in expand/collapse functionality."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Our ", React.createElement(_components.a, {
    href: "/components/page/#footer"
  }, "Page"), " has a new footer feature."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "We added more robust theme support, with the immediate goal of providing light and dark styles for iOS and Android."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "june-2021",
    style: {
      position: "relative"
    }
  }, "June 2021", React.createElement(_components.a, {
    href: "#june-2021",
    "aria-label": "june 2021 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v9.4.0"), React.createElement(Tag, null, "v9.5.0")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "We calibrated our token values across most components. This was a major step towards theme support and brought more color consistency across our components. Figma assets were also updated to reflect these changes."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "We made many minor bugfixes to overlay components."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "may-2021",
    style: {
      position: "relative"
    }
  }, "May 2021", React.createElement(_components.a, {
    href: "#may-2021",
    "aria-label": "may 2021 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v9.1.0"), React.createElement(Tag, null, "v9.2.0")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "We remade our homepage. It now houses more content for designers and developers."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Our project management of the project shifted from Github to Jira"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "We released a ", React.createElement(_components.a, {
    href: "/components/scroll"
  }, "Scroll"), " component."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Form Groups were made responsive."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "april-2021",
    style: {
      position: "relative"
    }
  }, "April 2021", React.createElement(_components.a, {
    href: "#april-2021",
    "aria-label": "april 2021 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v8.3.0"), React.createElement(Tag, null, "v8.4.0"), React.createElement(Tag, null, "v9.0.0")), "\n", React.createElement(_components.h3, {
    id: "new-features",
    style: {
      position: "relative"
    }
  }, "New features", React.createElement(_components.a, {
    href: "#new-features",
    "aria-label": "new features permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Our Page component now supports a ", React.createElement(_components.a, {
    href: "/components/page/#panel"
  }, "Panel"), " variation."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "We released a ", React.createElement(_components.a, {
    href: "/components/date-range-picker/"
  }, "Date Range Picker"), " component."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Buttons now support a ", React.createElement(_components.a, {
    href: "/components/button/#selected"
  }, "selected state"), "."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "march-2021",
    style: {
      position: "relative"
    }
  }, "March 2021", React.createElement(_components.a, {
    href: "#march-2021",
    "aria-label": "march 2021 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(TagGroup, null, React.createElement(Tag, null, "v8.1.0"), React.createElement(Tag, null, "v8.2.0")), "\n", React.createElement(_components.h3, {
    id: "new-features-1",
    style: {
      position: "relative"
    }
  }, "New features", React.createElement(_components.a, {
    href: "#new-features-1",
    "aria-label": "new features 1 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Our Page component now supports an ", React.createElement(_components.a, {
    href: "/components/page/#action-toolbar"
  }, "Action Toolbar"), " variation."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "We added a focus scope hook, improving the ability to manage focus across the app."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Our Select now supports ", React.createElement(_components.a, {
    href: "/components/anvil-select/#custom-content"
  }, "custom content in the trigger"), "."), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
